<template>
  <div>
    <p style="margin-top: 30px; font-size: 20px">证书人员列表</p>

    <el-button type="primary" plain @click="exportDataToExcel" style="margin-top: 10px;margin-left: 75% ">导出excel
    </el-button>
    <el-table
        class="personTable"
        stripe
        size="medium"
        ref="multipleTable"
        :data="certReviewTable"
        tooltip-effect="dark"
    >
      <el-table-column
          prop="id"
          label="ID"
          min-width="30%"
      >

      </el-table-column>
      <el-table-column
          prop="name"
          label="姓名"
          min-width="30%"
      >

      </el-table-column>
      <el-table-column
          prop="idCard"
          label="身份证"
          min-width="80%">

      </el-table-column>
      <el-table-column
          prop="majorName"
          label="工种">

      </el-table-column>

      <el-table-column
          prop="company"
          label="工作单位">

      </el-table-column>
      <el-table-column
          prop="org"
          label="机构"
          min-width="40%"
      >


      </el-table-column>
      <el-table-column
          prop="reinspectionType"
          label="复检状态"
          min-width="50%"
      >

      </el-table-column>
      <el-table-column
          prop="year"
          label="复检日期"
          min-width="35%"
      >

      </el-table-column>
      <el-table-column
          prop="certNo"
          label="证书号"
          min-width="55%"
      >

      </el-table-column>
    </el-table>
    <div>
      <p class="pagina" style="float: left; font-size: 15px">总共{{ this.certReviewCount }}条信息,
        每页显示{{ this.pageSize }}条信息, 共{{ page }}页</p>
      <el-pagination
          class="pagina"
          style="float: right;"
          background
          layout="prev, pager, next"
          @current-change="getCertList"
          :total="certReviewCount"
          :current-page.sync="certReviewCurrPage"
      >
      </el-pagination>
    </div>

  </div>

</template>

<script>
import request from "@/util/request";
import qs from "querystring";

export default {
  name: "Index",
  data() {
    return {
      pageSize: 10,
      certReviewTable: [],
      currPage: 0,
      certReviewCount: 0
    }
  }, methods: {
    getCertList(val) {
      this.$store.commit("setCertReviewPage", val);
      request({
        url: '/certReview/getCertReviewPage.do',
        params: {
          pageNum: this.certReviewCurrPage,
          pageSize: this.pageSize,
          batchId: this.$store.state.certReviewBatchId
        },
        method: "GET",
      }, (data) => {
        this.certReviewTable = data.data.list
        this.certReviewCount = data.data.total
      }, (err) => {
      }, this.$store.getters.token())
    }, exportDataToExcel() {
      let xhr = new XMLHttpRequest();
      let message = this.$message;
      xhr.responseType = "arraybuffer";
      xhr.open("POST", "http://tzzy.hnjsrcw.com/springboot-hntzzy/certReview/exportCertReviewToExcelByBatchId.do", true);
      xhr.onload = function () {
        if (this.status) {
          message({
            message: '导出成功,耐心等待文件生成',
            type: 'success'
          });
          let fileName = decodeURI(this.getResponseHeader("Content-Disposition").replace('attachment;filename=', ''))
          const blob = new Blob([this.response], {type: "application/vnd.ms-excel"});
          if (blob.size < 1) {
            alert('导出失败，导出的内容为空！');
            return;
          }
          if (window.navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, fileName)
          } else {
            const aLink = document.createElement('a');
            aLink.style.display = 'none';
            aLink.href = window.URL.createObjectURL(blob);
            aLink.download = fileName;
            document.body.appendChild(aLink);
            aLink.click();
            document.body.removeChild(aLink);

          }
        } else {
          message({
            message: '导出失败,请重新尝试',
            type: 'error'
          });
        }
      }
      xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded')
      xhr.setRequestHeader("Authentication", this.$store.getters.token());

      xhr.send(qs.stringify({batchId: this.$store.state.certReviewBatchId}))
    }


  }, computed: {

    personCount() {
      return this.personCount;
    },
    certReviewCurrPage: {
      get() {
        return this.$store.getters.certViewCurrPage;
      },
      set(val) {
        return this.$store.commit("setCertReviewPage", val);
      }
    },
    page() {
      let p = this.certReviewCount / this.pageSize;
      return Math.ceil(p);
    }
  }, created() {
    request({
      url: '/certReview/getCertReviewPage.do',
      params: {
        pageNum: this.$store.getters.certViewCurrPage,
        pageSize: this.pageSize,
        batchId: this.$store.state.certReviewBatchId
      },
      method: "GET",
    }, (data) => {

      this.certReviewTable = data.data.list
      this.certReviewCount = data.data.total
    }, (err) => {
      console.log(err)
    }, this.$store.getters.token())
  }, deactivated() {
  }

}
</script>

<style>
.personTable {
  font-size: 12px;
  margin-top: 20px;
}

.pagina {
  margin-top: 30px;
  float: right
}
</style>